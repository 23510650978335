const hardStopText = '';

export const questionnaire = [
  {
    question: 'Have you been <b>diagnosed with insomnia</b>?',
    answers: [
      {
        answer: 'Yes',
        showConditional: false,
      },
      {
        answer: 'No',
        showConditional: false,
        error: {
          header:
            'Neurovalens is indicated for the treatment of chronic insomnia and not other disorders.',
          body: hardStopText,
        },
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'What is <b>your age</b>?',
    answers: [
      {
        answer: 'Under 22',
        showConditional: false,
        error: {
          header:
            'Neurovalens is not indicated for use in patients under the age of 22.',
          body: hardStopText,
        },
      },
      {
        answer: '22+',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'How many nights per month do you have trouble sleeping?',
    answers: [
      {
        answer: '1-7',
        showConditional: false,
      },
      {
        answer: '8-15',
        showConditional: false,
      },
      {
        answer: '16-24',
        showConditional: false,
      },
      {
        answer: '25+',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'Have you been diagnosed or are you suspected to have epilepsy?',
    answers: [
      {
        answer: 'Yes',
        error: {
          header: 'Neurovalens has not been studied in people with epilepsy.',
          body: hardStopText,
        },
        showConditional: false,
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'Do you have vestibular dysfunction or inner ear disease?',
    answers: [
      {
        answer: 'Yes',
        error: {
          header:
            'Neurovalens has not been studied in people with vestibular or inner ear disease.',
          body: hardStopText,
        },
        showConditional: false,
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'Please select your <b>sex assigned at birth</b>.',
    answers: [
      {
        answer: 'Female',
        showConditional: true,
      },
      {
        answer: 'Male',
        showConditional: false,
      },
    ],
    type: 'radio',
    standardDemo: 'sex',
    isConditional: false,
  },
  {
    question: 'Are you <b>pregnant</b>?',
    answers: [
      {
        answer: 'Yes',
        showConditional: false,
        error: {
          header:
            'The safety of electrical stimulation during pregnancy has not been established.',
          body: hardStopText,
        },
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: true,
  },
  {
    question:
      'In the <b>past 3 months</b> have you had any <b>facial or brain trauma</b>?',
    answers: [
      {
        answer: 'Yes',
        showConditional: false,
        error: {
          header:
            'Persons with any facial or brain trauma in the past 3 month should not use Neurovalens. If you are interested in trying Neurovalens in the future, please visit us later.',
          body: hardStopText,
        },
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: '<b>Do you have any of the following?</b>',
    questionBulletPoints: [
      ' Implanted neurostimulator ',
      ' Cardiac pacemaker ',
      ' Defibrillator ',
      ' Implanted metallic or electronic device in your head (other than dental implants)? ',
      ' Wearable defibrillator',
    ],
    answers: [
      {
        answer: 'Yes',
        showConditional: false,
        error: {
          header:
            'Persons with implanted neurostimulator, cardiac pacemaker, defibrillator or any other implanted metallic or electronic device in the head or a wearable defibrillator should not use Neurovalens.',
          body: hardStopText,
        },
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question:
      'You are suffering from skin irritation behind the ears (e.g., eczema)',
    answers: [
      {
        answer: 'Yes',
        showConditional: false,
        error: {
          header:
            'Persons with skin irritation behind the ears should not use Neurovalens.',
          body: hardStopText,
        },
      },
      {
        answer: 'No',
        showConditional: false,
      },
    ],
    type: 'radio',
    isConditional: false,
  },
  {
    question: 'Please list any <b>allergies</b>.',
    answers: ['allergies'],
    type: 'freeText',
    standardDemo: 'allergies',
    isConditional: false,
  },
  {
    question:
      'Please list any <b>medical conditions</b> and any <b>prior surgeries</b>.',
    answers: ['medicalConditions'],
    type: 'freeText',
    standardDemo: 'medicalConditions',
    isConditional: false,
  },
  {
    question:
      'Please list any <b>current medicines</b>, <b>vitamins</b> or <b>dietary supplements</b> you take regularly. Please include the dosage.',
    answers: ['selfReportedMeds'],
    type: 'freeText',
    standardDemo: 'selfReportedMeds',
    isConditional: false,
  },
  {
    question:
      'Is there <b>anything else you want your doctor to know</b> about your condition or health?',
    answers: ['freeText'],
    type: 'freeText',
    isConditional: false,
  },
];
