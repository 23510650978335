const dev = {
  s3: {
    BUCKET: "stendrastaging209.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api-staging.belugahealth.com",
    // URL: 'http://localhost:9000',
  },
};

const prod = {
  s3: {
    BUCKET: "stendraprod209.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.belugahealth.com",
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...config,
};
