import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactInputVerificationCode from "react-input-verification-code";
import { routerMap } from "../utils/router";

const { verifyOTPForPhone } = routerMap;

const VerificationCodePlaceHolder = "·";
const OTPModal = (props) => {
  const {
    handleCloseVerificationCodeModal,
    showVerificationCodeModal,
    setIsPhoneVerified,
    setVerifiedPhoneNumber,
    localState,
  } = props;

  const [isVerifyingPhone, setIsVerifyingPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState("");

  const handleVerifyPhoneCode = async () => {
    if (
      verificationCode.length < 6 ||
      verificationCode.includes(VerificationCodePlaceHolder)
    ) {
      setPhoneCodeError("Please enter a 6 digit verification code");
      return;
    }
    setIsVerifyingPhone(true);
    const result = await verifyOTPForPhone(
      localState.phone,
      verificationCode,
      setPhoneCodeError,
    );
    setIsVerifyingPhone(false);
    if (result) {
      setIsPhoneVerified(true);
      setVerifiedPhoneNumber(localState.phone);
      handleCloseVerificationCodeModal();
    } else {
      setIsPhoneVerified(false);
    }
  };

  return (
    <Modal
      id="phone-verification-modal"
      show={showVerificationCodeModal}
      onHide={handleCloseVerificationCodeModal}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>VERIFICATION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-2">
          Please enter the verification code that we sent to your phone number.
        </div>
        <div className="custom-styles">
          <ReactInputVerificationCode
            length={6}
            onChange={(value) => {
              if (value && !value.includes("·")) {
                setVerificationCode(value);
                // TODO: do we want to automatically submit?
                // handleVerifyPhoneCode(value);
              }
            }}
          />
        </div>
        {phoneCodeError && (
          <div className="checkoutError">{phoneCodeError}</div>
        )}
        {/* <div className="verificationCodeTimer"> 30 secs</div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isVerifyingPhone}
          variant="secondary"
          onClick={handleCloseVerificationCodeModal}
        >
          Close
        </Button>
        <Button
          disabled={isVerifyingPhone || verificationCode.length < 6}
          variant="primary"
          onClick={handleVerifyPhoneCode}
        >
          Verify
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OTPModal;
