import React, { useState, useEffect, useCallback } from "react";

import { dataSecure } from "../DataFiles/standardText";
import { routerMap } from "../utils/router";
import { alphaMap } from "../utils/trackingUtils";

import "../MainForm/MainForm.css";
import {
  stateNameToAbbreviationMap,
  statesFullNameList,
} from "../DataFiles/states";
import OTPModal from "./OTPModal";

const DemographicForm = (props) => {
  const { onFormValidated, setValidated } = props;

  const [localState, setLocalState] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });
  const [localCardInfo, setLocalCardInfo] = useState({
    cardNum: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });
  const [zipLength, setZipLength] = useState(0);
  const [phoneError, setPhoneError] = useState("");
  const [zipError, setZipError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(null);

  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  const { verifyPhone, sendPhoneVerification } = routerMap;

  const handleCloseVerificationCodeModal = () =>
    setShowVerificationCodeModal(false);

  const handleShowVerificationCodeModal = async () => {
    const result = await sendPhoneVerification(localState.phone, setPhoneError);
    if (result) {
      setShowVerificationCodeModal(true);
    }
  };

  function phoneMask(phone) {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/(\d{3})(\d{1,4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  }

  const handleChange = async (e) => {
    const localDob = localState.dob ? localState.dob.split("/") : [0, 0, 0];
    switch (e.target.name) {
      case "zip":
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        setZipLength(e.target.value.length);
        break;
      case "phone":
        e.target.value = phoneMask(e.target.value);
        const cleanedPhone = e.target.value.replace(/\D/g, "");
        if (cleanedPhone.length === 10) {
          if (!isValidatingPhone) {
            const isValidPhone = await validatePhone(cleanedPhone);
            if (isValidPhone) {
              setLocalState((prev) => ({
                ...prev,
                [e.target.name]: cleanedPhone,
              }));
            }
          }
        } else {
          setIsPhoneValidated(false);
        }
        break;
      case "dobMonth":
        setLocalState({
          ...localState,
          dob: `${e.target.value}/${localDob[1]}/${localDob[2]}`,
        });
        break;
      case "dobDate":
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${e.target.value}/${localDob[2]}`,
        });
        break;
      case "dobYear":
        setLocalState({
          ...localState,
          dob: `${localDob[0]}/${localDob[1]}/${e.target.value}`,
        });
        break;
      case "cardNum":
      case "cvc":
        setLocalCardInfo({
          ...localCardInfo,
          [e.target.name]: e.target.value,
        });
        break;
      case "expYear":
      case "expMonth":
        const formattedNum = String(e.target.value).padStart(2, "0");
        setLocalCardInfo({
          ...localCardInfo,
          [e.target.name]: formattedNum,
        });
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const validatePhone = async (phone) => {
    setIsValidatingPhone(true);
    setPhoneError("");
    if (phone.length === 10) {
      const result = await verifyPhone(phone, setPhoneError);
      setIsValidatingPhone(false);
      setIsPhoneValidated(result);
      return result;
    } else {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return false;
    }
  };

  const handleAgeBlur = () => {
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate(),
    );
    const dobArray = localState.dob ? localState.dob.split("/") : [0, 0, 0];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      setAgeError(
        "Neurovalens is not indicated for use in patients under the age of 18.",
      );
      setValidated(false);
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleZipBlur = () => {
    if (zipLength < 5) {
      setZipError("Please enter a valid zip code");
    } else {
      setZipError("");
    }
  };

  const handleEmailBlur = () => {
    const regex = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/;
    const regMatch = regex.test(localState.email);
    if (!regMatch) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    if (
      localState.firstName.length &&
      localState.lastName.length &&
      localState.email.length &&
      localState.phone.length &&
      localState.address.length &&
      localState.city.length &&
      localState.state.length &&
      localState.zip.length &&
      localState.dob.length &&
      !ageError &&
      !zipError &&
      !phoneError &&
      !emailError &&
      isPhoneVerified &&
      isPhoneValidated
    ) {
      if (localState.phone !== verifiedPhoneNumber) {
        setPhoneError("Please validate your phone number");
        // if they enter a different phone number after, we'll
        // need to have them re-verify their new phone
        setIsPhoneVerified(false);
        setValidated(false);
        return;
      }

      if (
        Object.keys(localCardInfo).every((key) => {
          return localCardInfo[key].length;
        })
      ) {
        setValidated(true);
        onFormValidated(localState, localCardInfo);
      } else {
        setValidated(false);
      }
    } else {
      setValidated(false);
    }
  }, [
    localState,
    localCardInfo,
    ageError,
    phoneError,
    zipError,
    emailError,
    onFormValidated,
    isPhoneValidated,
    isPhoneVerified,
    verifiedPhoneNumber,
    setValidated,
  ]);

  return (
    <div className="demographicContainer">
      <div className="checkoutForm">
        <div>
          <div className="dataSecureText">
            {dataSecure.header} For more information, click{" "}
            <a
              href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>

        <div className="demographicSectionLabel">Personal Information</div>

        <div className="demographicSubRow">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">First name</div>
            <input
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
          <div className="spacer" />
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Last name</div>
            <input
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
        </div>
        <div className="demographicSubRow emailAndPhone">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Email</div>
            <input
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleEmailBlur}
              className={
                emailError
                  ? "checkoutInput checkoutInputError"
                  : "checkoutInput"
              }
            />
            {emailError && <div className="checkoutError">{emailError}</div>}
          </div>
          <div className="spacer" />
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">
              Mobile phone number
            </div>
            <div className="phoneInputVerificationHolder">
              <input
                name="phone"
                placeholder="Phone"
                type="tel"
                onChange={handleChange}
                // maxLength='10'
                className={
                  phoneError
                    ? "checkoutInput checkoutInputError"
                    : "checkoutInput"
                }
              />
              {isPhoneVerified ? (
                <div className="phoneVerifiedIndication">
                  <div className="checkMark"></div>
                </div>
              ) : (
                <button
                  disabled={!isPhoneValidated}
                  className="phoneVerificationButton orderButton"
                  onClick={handleShowVerificationCodeModal}
                >
                  Verify
                </button>
              )}
            </div>
            {phoneError && <div className="checkoutError">{phoneError}</div>}
          </div>
          <OTPModal
            localState={localState}
            handleCloseVerificationCodeModal={handleCloseVerificationCodeModal}
            showVerificationCodeModal={showVerificationCodeModal}
            setVerifiedPhoneNumber={setVerifiedPhoneNumber}
            setIsPhoneVerified={setIsPhoneVerified}
          />
        </div>
        <div className="demographicSubRow dobAndAddress">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Date of birth</div>
            <div className="dobInputs">
              <input
                name="dobMonth"
                placeholder="MM"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobDate"
                placeholder="DD"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobYear"
                placeholder="YYYY"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputShort checkoutInputError"
                    : "checkoutInputShort"
                }
                maxLength="4"
              />
            </div>
            {ageError && <div className="checkoutError">{ageError}</div>}
          </div>
          <div className="spacer" />
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Address</div>
            <input
              name="address"
              placeholder="Address"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
        </div>
        <div className="demographicSubRow">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">City</div>
            <input
              name="city"
              placeholder="City"
              onChange={handleChange}
              className="checkoutInput"
            />
          </div>
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">State</div>
            <div className="checkoutInputShort state">
              <label>
                <select
                  name="state"
                  id="state-select"
                  onChange={handleChange}
                  required
                  defaultValue=""
                >
                  <option value="" disabled>
                    State
                  </option>
                  {statesFullNameList.map((name) => {
                    return (
                      <option
                        value={stateNameToAbbreviationMap[name]}
                        key={name}
                      >
                        {name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Zip Code</div>
            <input
              name="zip"
              placeholder="Zip"
              onChange={handleChange}
              onBlur={handleZipBlur}
              className={
                zipError
                  ? "checkoutInputShort zip checkoutInputError"
                  : "checkoutInputShort zip"
              }
              maxLength="5"
            />
            {zipError && <div className="checkoutError">{zipError}</div>}
          </div>
        </div>

        <div className="demographicSectionLabel">Payment Details</div>

        <div className="ccContainer">
          <div className="ccDetails">
            <div>
              <div className="formFieldSmall checkoutField">
                Credit Card Number
              </div>
              <input
                name="cardNum"
                placeholder="0000 0000 0000 0000"
                onChange={handleChange}
                className="checkoutInput"
              />
            </div>
            <div className="cardDetailsRow">
              <div className="expiryDateHolder">
                <div className="formFieldSmall checkoutField">Expiry Date</div>
                <div>
                  <input
                    name="expMonth"
                    placeholder="MM"
                    onChange={handleChange}
                    className="expiryDateInput"
                    maxLength="2"
                  />
                  <span className="expirySlash">/</span>
                  <input
                    name="expYear"
                    placeholder="YY"
                    onChange={handleChange}
                    className="expiryDateInput"
                    maxLength="2"
                  />
                </div>
              </div>
              <div className="cvcHolder">
                <div className="formFieldSmall checkoutField">CVV</div>
                <input
                  name="cvc"
                  placeholder="***"
                  onChange={handleChange}
                  className="cvcInput"
                />
              </div>
            </div>
          </div>
          <div className="priceBox">
            <div className="demographicSectionLabel">Doctor Consultation</div>
            <div className="consultationPrice">$30</div>
          </div>
          <div className="issueContactInfo">
            If you are experiencing issues with this form, please contact
            Customer Care at{" "}
            <a href="mailto: team@neurovalens.com">team@neurovalens.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicForm;
